@charset "UTF-8";
html {
  font-size: 14px;
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  hyphens: auto;
  color: #323232;
  font-size: 12px;
}

a {
  color: #0077b3;
  text-decoration: none;
  border-bottom: 1px solid currentColor;
}
a:hover, a:focus {
  color: #004466;
}

.container {
  max-width: 960px;
  margin: auto;
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 42px 0 42px;
  font-size: 0;
}
.main-header__logo svg {
  width: 42px;
  height: 42px;
  fill: #0088cc;
}

.main-header__logo,
.main-header-support {
  flex-grow: 0;
  flex-basis: 0;
}

.main-header-nav {
  flex-grow: 1;
}
.main-header-nav__ul {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;
}
.main-header-nav__item {
  font-size: 19px;
  margin-right: 18px;
  font-weight: 500;
  color: #0088CC !important;
}
.main-header-nav__item a {
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid transparent;
}
.main-header-nav__item a:hover {
  color: #0077b3;
}

.logocat {
  width: 50px;
}

.main-header-support {
  display: flex;
  align-items: center;
}
.main-header-support__icon {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #FFF;
}
.main-header-support__icon:hover, .main-header-support__icon:focus {
  box-shadow: 0 0 0 2px #00aaff;
}
.main-header-support__icon svg {
  display: block;
  width: 100%;
  height: 100%;
}
.main-header-support__icon + .main-header-support__icon {
  margin-left: 22px;
}

.fg-block {
  border-radius: 23px;
  box-shadow: 0 0 25px 10px #f1f1f1;
  background-color: #ffffff;
  padding: 25px;
  font-size: 15px;
  display: inline-block;
  line-height: 18px;
  text-align: center;
  max-width: 100%;
  box-sizing: border-box;
  transition: all 1s ease;
}
.fg-block p:first-child {
  margin-top: 0;
}
.fg-block p:last-child {
  margin-bottom: 0;
}

.fg-block + .fg-block {
  margin-left: 48px;
}

.qr-box {
  transition: all 1s ease;
}

.qr-box:hover {
  transform: scale(1.05);
  transition: all 1s ease;
}

.qr-max {
  max-width: 256px;
  cursor: none;
}

.hero-primary {
  text-align: center;
  font-size: 15px;
  padding: 19px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hero-primary p {
  margin: 0;
  padding: 0;
}

.hero-primary-pkey {
  display: flex;
  align-items: center;
  border: 1px solid #0088cc;
  border-radius: 10px;
  margin-top: 19px;
}
.hero-primary-pkey__input {
  flex-grow: 1;
  appearance: none;
  border: none;
  font-size: 14px;
  font-family: inherit;
  padding: 14px 0 14px 14px;
  font-weight: 300;
  background: transparent;
}
.hero-primary-pkey__buttons {
  flex-shrink: 1;
}
.hero-primary-pkey__button {
  appearance: none;
  background: transparent;
  outline: none;
  border: none;
  padding: 0 14px;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.hero-primary-pkey__button svg {
  width: 24px;
  height: 24px;
  fill: #0088cc;
}
.hero-primary-pkey__button:hover svg, .hero-primary-pkey__button:focus svg {
  fill: #006699;
}

.hero-primary-metrics-container {
  margin: 25px 0 0;
}

.hero-primary-metrics {
  width: 100%;
  display: flex;
  margin: auto;
}
.hero-primary-metrics-item {
  flex-grow: 1;
  text-align: center;
  color: #14384d;
  white-space: nowrap;
}
.hero-primary-metrics-item__key {
  font-size: 14px;
  font-weight: 300;
  margin-top: 6px;
}
.hero-primary-metrics-item__value {
  font-weight: 600;
  font-size: 24px;
}
.hero-primary-metrics-item__value[data-metric=stability]::after {
  content: "%";
}
.hero-primary-metrics-item__value[data-metric=commission]::after {
  content: "%";
}
.hero-primary-metrics__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  border-radius: 10px;
  background-color: #0088cc;
  color: #FFF;
  font-size: 15px;
  line-height: 2.5;
  margin: 12px auto 0;
  max-width: 100%;
  cursor: pointer;
  font-weight: 500;
}
.hero-primary-metrics__button svg {
  height: 20px;
  width: 20px;
  fill: currentColor;
  margin-right: 14px;
}
.hero-primary-metrics__button:hover, .hero-primary-metrics__button:focus {
  background-color: #252b43;
}

.hero-primary-services {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-primary-services__service {
  display: flex;
  width: 48px;
  height: 48px;
  background: #0088cc;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  border: none;
  transition: 0.2s background ease;
}
.hero-primary-services__service svg {
  fill: #FFF;
  height: auto;
  width: auto;
  width: 100%;
  max-width: 60%;
}
.hero-primary-services__service:hover {
  background: #0077b3;
}
.hero-primary-services__service + .hero-primary-services__service {
  margin-left: 32px;
}

.main-footer {
  background: #0088cc;
  padding: 12px;
  text-align: center;
}
.main-footer__logo {
  width: 32px;
  height: 32px;
  fill: #3dbeff;
  flex-shrink: 1;
}
.main-footer__menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 12px;
  letter-spacing: 1px;
}
.main-footer__menu li::after {
  content: "·";
  margin: 0 10px;
  color: #29b8ff;
}
.main-footer__menu li:last-child::after {
  display: none;
}
.main-footer__menu a {
  color: #75d1ff;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: 0.1s all ease;
}
.main-footer__menu a:hover, .main-footer__menu a:focus {
  border-color: currentColor;
  color: #ade4ff;
}

.footer-container {
  display: flex;
  align-items: center;
}

.main-walkthrough {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 19px;
  margin: 80px auto;
}

.main-walkthrough-item {
  text-align: center;
  font-size: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-walkthrough-item__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-walkthrough-item__icon svg {
  height: 136px;
}
.main-walkthrough-item__text {
  margin: 19px 0;
  font-size: 16px;
  line-height: 1.5;
  flex-grow: 1;
  max-width: 256px;
}
.main-walkthrough-item__button {
  display: block;
  cursor: pointer;
  font-size: 20px;
  margin: auto;
  color: #303757;
  text-decoration: none;
  border: 1px solid #0088cc;
  border-radius: 14px;
  width: 100%;
  max-width: 280px;
  line-height: 2.15;
  height: 45px;
  box-sizing: border-box;
}
.main-walkthrough-item__button:hover {
  background: linear-gradient(284deg, #303757, #303757);
  color: #FFF;
}

.pubkey-icon-inner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-family: "Ubuntu Mono", "Roboto Mono", monospace;
  font-weight: 700;
  line-height: 1.2;
  font-size: 14px;
  color: #303757;
}

.validator-info {
  margin: 0 0 32px 0;
}
.validator-info__fg-block {
  margin: 80px auto;
  display: flex;
}
.validator-info__text {
  margin-right: 64px;
}
.validator-info__image {
  width: 210px;
  height: 165px;
  min-width: 210px;
}

.api-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 0;
}
.api-info__image {
  height: 220px;
  margin-right: 24px;
}
.api-info__header {
  margin: 0 0 38px 0;
  font-size: 40px;
}
.api-info__header span {
  color: #0088cc;
}
.api-info__text {
  flex-grow: 1;
  font-size: 17px;
  line-height: 1.42em;
  text-align: center;
  max-width: 60%;
  margin: auto;
}

.app-info {
  background-image: linear-gradient(to right, #d73e68 0%, #fbc79a 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFF;
  text-align: center;
  margin: 80px 0;
}
.app-info__content_wrap {
  flex-shrink: 1;
  margin: 56px 0;
}
.app-info__title {
  font-size: 40px;
  margin: 0 0 25px 0;
}
.app-info__text {
  font-size: 19px;
  line-height: 1.25em;
  max-width: 70%;
  margin: auto;
}
.app-info__image {
  display: block;
  flex-basis: 100%;
}

.app-info-icons {
  display: block;
  text-align: center;
  margin-top: 50px;
}
.app-info-icons__icon {
  text-decoration: none;
}
.app-info-icons__icon svg {
  height: 32px;
  fill: #FFF;
  max-width: 32px;
}
.app-info-icons__icon + .app-info-icons__icon {
  margin-left: 34px;
}
.app-info-icons__disclaimer {
  font-weight: 300;
  font-size: 12px;
  margin-top: 25px;
}

.main-faq {
  margin: 80px 0;
  text-align: center;
}
.main-faq__header {
  font-size: 40px;
  font-weight: 700;
  margin: 0 0 25px 0;
}
.main-faq__lead {
  font-size: 16px;
  line-height: 27px;
}
.main-faq dt {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 12px;
  margin-top: 28px;
}
.main-faq dd {
  width: 75%;
  max-width: 550px;
  margin: auto;
  text-align: left;
  font-size: 16px;
  line-height: 1.44em;
}

.swipe-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px 0 0 0;
  font-size: 0;
  line-height: 0;
}
.swipe-dots span {
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #d8d8d8;
  margin-right: 7px;
}
.swipe-dots span:last-child {
  margin-right: 0;
}
.swipe-dots span.active {
  background: #0088cc;
}

@media all and (min-width: 640px) {
  .desktop-hidden {
    display: none;
  }
}
@media all and (max-width: 640px) {
  html {
    font-size: 14px;
  }

  .mobile-hidden {
    display: none;
  }

  .container {
    padding: 0 20px;
  }

  .fg-block {
    padding: 20px;
    font-size: 15px;
    line-height: 21px;
  }

  .main-header {
    margin: 24px 0;
  }
  .main-header__logo svg {
    width: 44px;
    height: 44px;
  }

  .main-header-support__icon {
    width: 32px;
    height: 32px;
  }
  .main-header-support__icon + .main-header-support__icon {
    margin-left: 18px;
  }

  .hero-primary-metrics-container {
    margin: 15px 0 5px;
  }

  .hero-primary-metrics-item__key {
    font-size: 15px;
  }
  .hero-primary-metrics-item__value {
    font-size: 21px;
  }

  .main-walkthrough {
    grid-template-columns: none;
    grid-row-gap: 32px;
    margin: 38px auto 0;
  }

  .main-walkthrough-item__icon svg {
    height: 150px;
  }
  .main-walkthrough-item__text {
    font-size: 14px;
    line-height: 18px;
  }
  .main-walkthrough-item__button {
    font-size: 20px;
    height: 45px;
    max-width: auto;
  }

  .pubkey-icon-inner-text {
    font-size: 12px;
  }

  .validator-info {
    margin: 70px 0 0 0;
  }
  .validator-info__fg-block {
    flex-direction: column;
  }
  .validator-info__text {
    margin: 0;
  }
  .validator-info__image {
    width: 100%;
    height: auto;
    margin-top: 25px;
    min-width: 100px;
    display: none;
  }

  .api-info {
    margin: 42px auto 0px;
  }
  .api-info__header {
    font-size: 25px;
    margin-bottom: 21px;
  }
  .api-info__text {
    font-size: 16px;
    line-height: 21px;
    max-width: 90%;
  }

  .app-info {
    margin-top: 0;
    margin-bottom: 0;
  }
  .app-info__content_wrap {
    margin: 20px 40px;
  }
  .app-info__title {
    font-size: 25px;
    margin-bottom: 18px;
  }
  .app-info__text {
    font-size: 12px;
    line-height: 17px;
    max-width: 100%;
  }

  .app-info-icons {
    margin-top: 25px;
  }
  .app-info-icons__disclaimer {
    font-size: 9px;
  }

  .main-faq {
    margin-top: 50px;
  }
  .main-faq__header {
    margin-bottom: 5px;
    font-size: 25px;
  }
  .main-faq__lead {
    font-size: 14px;
  }
  .main-faq dt {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .main-faq dd {
    width: auto;
    max-width: unset;
    margin: 0 40px;
    font-size: 14px;
    line-height: 18px;
  }

  .main-footer__logo {
    display: none;
  }
  .main-footer__menu {
    flex-direction: column;
  }
  .main-footer__menu li::after {
    display: none;
  }
  .main-footer__menu a {
    padding: 6px 0;
    display: block;
  }

  .swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;
    display: block;
  }

  .swipe-wrap {
    overflow: hidden;
    position: relative;
    display: block;
  }

  .swipe-wrap > div {
    width: 100%;
    position: relative;
    overflow: hidden;
    float: left;
    box-sizing: border-box;
  }
}